/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("jquery");
window.jQuery = $;
window.$ = $;
require("@rails/actiontext");
require("bootstrap");
require("select2");
require("../src/cocoon.js.erb");
require("../src/select_all");
import flatpickr from "flatpickr";
import $ from "jquery";
import Trix from 'trix'
window.Trix = Trix;


(function () {
  var scrollPosition;

  document.addEventListener(
    "turbolinks:load",
    function () {
      if (scrollPosition) {
        window.scrollTo.apply(window, scrollPosition);
        scrollPosition = null;
      }
    },
    false
  );

  Turbolinks.reload = function () {
    console.info("setting scroll position")
    scrollPosition = [window.scrollX, window.scrollY];
    Turbolinks.visit(window.location, { action: "replace" });
  };
})();

function create_custom_transport(e) {
  e.preventDefault();

  var selected_checkboxes = $("input[data-role~=mass_select_checkbox]:checked");
  var selected_checkboxes_ids = selected_checkboxes.map(function () {
    return this.value;
  });

  if (selected_checkboxes_ids.length === 0)
    return alert("Choose at least one transport order");

  $.ajax({
    url: "/transport_orders/create_custom_transport",
    method: "GET",
    type: "script",
    data: {
      transport_order_ids: selected_checkboxes_ids.toArray(),
    },
  });
}

function delete_custom_transport(e) {
  e.preventDefault();

  var selected_checkboxes = $("input[data-role~=mass_select_checkbox]:checked");
  var selected_checkboxes_ids = selected_checkboxes.map(function () {
    return this.value;
  });

  if (selected_checkboxes_ids.length === 0)
    return alert("Choose at least one transport order");

  $.ajax({
    url: "/transport_orders/delete_custom_transport",
    method: "GET",
    type: "script",
    data: {
      transport_order_ids: selected_checkboxes_ids.toArray(),
    },
  });
}

function initApplicationPlugins() {
  
  window.addEventListener("trix-file-accept", function(event) {
      event.preventDefault()
      alert("File attachment not supported!")
    })

  
  
  flatpickr(".transport_flatpickr_date", {
    allowInput: true,
    onChange: function (selectedDates, dateStr, instance) {
      $(instance.input).closest("form").submit();
    },
  });

  flatpickr(".flatpickr_date", { allowInput: true });
  flatpickr(".flatpickr_time", {
    allowInput: true,
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
    time_24hr: true,
  });
  
  flatpickr(".flatpickr_all", {
    allowInput: true,
    enableTime: true,
    time_24hr: true,
  });
  

  $(".flatpickr_time").on("blur", function (e) {
    e.stopPropagation();
    console.info("blur");
    console.info(e.target.value);
    e.target._flatpickr.setDate(e.target.value);
  });

  $('[data-toggle="tooltip"]').tooltip();

  $("[data-behavior~=create_custom_transport]").on(
    "click",
    create_custom_transport
  );
  $("[data-behavior~=delete_custom_transport]").on(
    "click",
    delete_custom_transport
  );

  $(".select2_tags").select2({
    tags: true,
    tokenSeparators: [",", " "],
    theme: "bootstrap",
  });

  $(document).on(
    "change",
    "[data-role~=transport_order_checkbox]",
    function () {
      if ($(this).prop("checked"))
        $(this).closest(".transport_order").addClass("selected");
      else $(this).closest(".transport_order").removeClass("selected");
    }
  );

  $(document).ready(function () {
    // show the alert
    setTimeout(function () {
      $(".alert").alert("close");
    }, 5000);
  });
}
window.initApplicationPlugins = initApplicationPlugins;

document.addEventListener("turbolinks:load", function () {
  initApplicationPlugins();
});

var reloadWithTurbolinks = (function () {
  var scrollPosition;

  function reload() {
    scrollPosition = [window.scrollX, window.scrollY];
    Turbolinks.visit(window.location.toString(), { action: "replace" });
  }

  document.addEventListener("turbolinks:load", function () {
    if (scrollPosition) {
      window.scrollTo.apply(window, scrollPosition);
      scrollPosition = null;
    }
  });

  return reload;
})();
